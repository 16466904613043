import React from "react"
import Header from "../components/header"
import { Helmet } from "react-helmet"

import Style from "../styles/contact.module.css"
import LinkedInLogo from "../images/LinkedIn-Logo.png"
import GithubLogo from "../images/Github-Logo.png"

export default () => (
  <div>
    <Helmet>
      <title>Maximo Macchi - Contact</title>
    </Helmet>
    <Header />
    <div className={Style.contentGroup}>
      <div className={Style.headerContainer}>
        <h1 className={Style.contactHeader}>Connect with me!</h1>
        <div className={Style.contactImages}>
          <a href="https://www.linkedin.com/in/maximomacchi/">
            <img src={LinkedInLogo} alt="LinkedIn"></img>
          </a>
          <a href="https://github.com/maximomacchi">
            <img src={GithubLogo} alt="GitHub"></img>
          </a>
        </div>
      </div>
      <form
        name="contact"
        method="POST"
        className={Style.formContainer}
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact"></input>
        <input
          type="text"
          name="Name"
          placeholder="Name"
          className={Style.formInput}
          required
        ></input>
        <input
          type="email"
          name="Reply To"
          placeholder="Email"
          className={Style.formInput}
          required
        ></input>
        <input
          type="text"
          name="Subject"
          placeholder="Subject"
          className={Style.formInput}
          required
        ></input>
        <textarea
          name="Message"
          placeholder="Message"
          className={`${Style.formInput} ${Style.formMessage}`}
          required
        ></textarea>
        {/* <input type="text" name="_gotcha" className={Style.spamFilter} /> */}
        {/* <input
          type="submit"
          value="Submit"
          className={Style.formSubmit}
        ></input> */}
        {/* <div className={ContactStyle.submitContainer}> */}
        <button type="submit" className={Style.formSubmit}>
          Submit
        </button>
        {/* </div> */}
      </form>
    </div>
  </div>
)
